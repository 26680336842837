import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Input from '../../elements/Input';

// Validation schema
const validationSchema = Yup.object({
  fullName: Yup.string().required('Full Name is required'),
  company: Yup.string().required('Company/Organization is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number must be digits only')
    .min(10, 'Phone number must be at least 10 digits')
    .required('Phone number is required'),
    email: Yup.string()
    .email("Invalid email format")
    .required("Company email is required"),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

const ContactForm = () => {
  return (
    <div style={{margin:'5px',padding:'20px',backgroundColor:'#f1f4fd',borderRadius:'10px'}}>
      <Formik
        initialValues={{
          fullName: '',
          company: '',
          phone: '',
          email:'',
          subject: '',
          message: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div style={{margin:'5px 25px'}}>
              <div className="grid grid-cols-1 gap-2">
            <div>
              <label htmlFor="fullName" className='block text-sm font-medium leading-6 text-gray-700'>Full Name*</label>
             <Field type="text"  name="fullName"  className="block w-full flex-1 border-0  py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
              <ErrorMessage name="fullName" component="div" style={{color:'#e33636'}} />
            </div>

            <div>
              <label htmlFor="company" className='block text-sm font-medium leading-6 text-gray-700'>Company / Organization*</label>
              <Field type="text" name="company"  className="block w-full flex-1  py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:border-[#f45513] sm:text-sm sm:leading-6" />
              <ErrorMessage name="company" component="div" style={{color:'#e33636'}}/>
            </div>
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mt-2">
            <div>
              <label htmlFor="phone" className='block text-sm font-medium leading-6 text-gray-700'>Phone*</label>
              <Field type="text" name="phone"  className="block w-full flex-1 border-0  py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
              <ErrorMessage name="phone" component="div"style={{color:'#e33636'}} />
            </div>

            <div>
              <label htmlFor="email" className='block text-sm font-medium leading-6 text-gray-700'>Company email*</label>
              <Field type="text" name="email"  className="block w-full flex-1 border-0  py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
              <ErrorMessage name="email" component="div" style={{color:'#e33636'}}/>
            </div>
</div>
            <div className='mt-2'>
              <label htmlFor="subject" className='block text-sm font-medium leading-6 text-gray-700'>Subject</label>
              <Field type="text" name="subject" className="block w-full flex-1 border-0  py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
              <ErrorMessage name="subject" component="div" style={{color:'#e33636'}}/>
            </div>

            <div className='mt-2'>
              <label htmlFor="message" className='block text-sm font-medium leading-6 text-gray-700'>Message*</label>
              <Field as="textarea" name="message" rows='4' className="block w-full flex-1 border-0  py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"  />
              <ErrorMessage name="message" component="div" style={{color:'#e33636'}} />
            </div>
<div style={{textAlign:'center',marginTop:'30px'}}>
            <button type="submit" disabled={isSubmitting} className="rounded-md bg-[#f45513] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#e04a0b] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Submit
            </button>
            </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
