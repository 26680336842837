import React, { useState } from "react";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const HeroSplit = ({ slides }) => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  const customStyles = {
    container: {
      backgroundColor: '#fff',
      position: 'relative',
      width: '100%',
      height: isMobile ? '90vh' : '100vh',
      overflow: 'hidden',
      margin: 0,
      padding: 0,
    },
    bulletContainer: {
      position: 'absolute',
      bottom: '20px',
      right: '100px',
      display: 'flex',
      justifyContent: 'flex-end',
      zIndex: 10,
    },
    bullet: {
      backgroundColor: '#888',
      border: 'none',
      width: '13px',
      height: '13px',
      margin: '0 5px',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    bulletActive: {
      backgroundColor: '#f45513',
      transform: 'scale(1.2)',
    },
    descriptionText: {
      maxWidth: '300px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'visible',
    },
    slideContainer: {
      display: 'flex',
      marginLeft: '-215px',
      flexDirection: 'row',
    },
    mobileSlideContainer: {
      flexDirection: 'column',
      marginLeft: '0',
      alignItems: 'center',
    },
    mobileImage: {
      width: '100%',
      height: 'auto',
    },
    textContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: '-600px',
      marginTop: '-190px'
    },
    mobileTextContainer: {
      marginLeft: 0,
      padding: '20px',
      textAlign: 'center',
      width: '100%'
    },
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const splitDescription = (description, maxLength) => {
    const regex = new RegExp(`.{1,${maxLength}}`, 'g');
    return description.match(regex);
  };

  const splitTitle = (title, maxLength) => {
    const regex = new RegExp(`.{1,${maxLength}}`, 'g');
    return title.match(regex);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [slides.length])

  return (
    <div>

      <div style={customStyles.container} id="home">
        <AwesomeSlider
          bullets={false}
          buttons={false}
          selected={activeIndex}
          onTransitionEnd={(e) => setActiveIndex(e.currentIndex)}
        >
          {slides.map((slide, index) => (
            <div key={index} style={{ backgroundColor: '#fff' }}>
              <div
                style={window.innerWidth <= 768 ? customStyles.mobileSlideContainer : customStyles.slideContainer}
              >
                <div style={{ display: 'flex', marginLeft: '-200', height: '1080px', width: isMobile ? '300vw':'100%',backgroundRepeat: isMobile ? 'no-repeat' :'', backgroundSize: isMobile ? "cover" : '', maxWidth: isMobile ? '100': '' }}>
                  <img
                    src={slide.image}
                    alt={`Slide ${index + 1}`}
                  //style={window.innerWidth <= 768 ? customStyles.mobileImage : { height: "700px" }}
                  />
                </div>
                {!isMobile && (
                  <div
                    style={
                      window.innerWidth <= 768
                        ? customStyles.mobileTextContainer
                        : customStyles.textContainer
                    }
                  >
                    <h1>
                      {splitTitle(slide.title, 23).map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}</h1>
                    <p style={customStyles.descriptionText}>
                      {splitDescription(slide.description, 56).map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                )}


              </div>
            </div>
          ))}
        </AwesomeSlider>

        <div style={customStyles.bulletContainer}>
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              style={index === activeIndex
                ? { ...customStyles.bullet, ...customStyles.bulletActive }
                : customStyles.bullet}
            />
          ))}

        </div>

        {isMobile && (
          <AwesomeSlider
            bullets={false}
            buttons={false}
            selected={activeIndex}
            onTransitionEnd={(e) => setActiveIndex(e.currentIndex)}
          >
            {slides.map((slide, index) => (
              <div key={index} style={{ backgroundColor: '#fff' }}>
                <div
                >

                  <div
                    style={
                      window.innerWidth <= 768
                        ? customStyles.mobileTextContainer
                        : customStyles.textContainer
                    }
                  >
                    <h1>
                      {splitTitle(slide.title, 13).map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}</h1>
                    <p style={customStyles.descriptionText}>
                      {splitDescription(slide.description, 30).map((line, lineIndex) => (
                        <React.Fragment key={lineIndex}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </div>

                </div>
              </div>
            ))}
          </AwesomeSlider>
        )}
      </div>
    </div>
  );
};

const App = () => {
  const slides = [
    {
      title: "Efficient IoT Schematic Analysis",
      description: "Optimized schematic analysis for efficient, low-power   IoT devices.",
      image: "9.png",
    },
    {
      title: "Stable Power System    Analysis",
      description: "Accurate voltage and load analysis for stable power     systems.",
      image: "4.png",
    },
    {
      title: "Streamlined Industrial Analysis",
      description: "Streamlined analysis for efficient industrial automation.",
      image: "5.png",
    },
    {
      title: "Advanced Circuit       Validation",
      description:
        "Reliable communication systems through advanced circuit validation.",
      image: "6.png",
    },
    {
      title: "Precise 5G Circuit     Insights",
      description: "Fast, precise analysis for 5G circuit designs and       protocols.",
      image: "7.png",
    },
    {
      title: "Reliable Heavy-Duty    Circuit Analysis",
      description: "Durable, reliable electrical analysis for heavy-duty    machines.",
      image: "8.png",
    },
    {
      title: "Enhanced Automotive    Electronics",
      description:
        "Enhanced safety and performance in automotive           electronics.",
      image: "3.png",
    }

  ];

  return <HeroSplit slides={slides} />;
};

export default App;
