import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { ChartNoAxesCombined } from "lucide-react";
import { Star } from "lucide-react";
import { FastForward } from "lucide-react";
import { Handshake } from "lucide-react";
import { Workflow } from "lucide-react";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "",
      paragraph: "",
    };

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    return (
      <section {...props} className={outerClasses} id="whyChoose">
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div style={{ textAlign: "center", alignItems: "center" }}>
              <h2>Why Choose CircuitSight.ai?</h2>
            </div>
            <div
              style={{
                textAlign: isMobile ? "left" : "center",
                alignItems: isMobile ? "flex-start" : "center",
                display: "flex",
                justifyContent: isMobile ? "flex-start" : "center",
                marginTop: "50px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  padding: "10px",
                  fontSize: isMobile ? "18px" : "20px",
                  lineHeight: "1.5",
                  marginBottom: "50px",
                }}
                className="text-sm"
              >
                Circuitsight.ai for cutting-edge automation, enhanced
                efficiency, and a seamless electrical schematic Analysis
                experience led by industry experts.
              </p>
            </div>
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 justify-end items-center">
                      <ChartNoAxesCombined
                        className="text-gray-500"
                        style={{
                          width: "50px",
                          height: "50px",
                          color: "#FFF",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">SIMPLE AUTOMATION</h4>
                    <p className="m-0 text-sm">
                      CircuitSight.ai delivers seamless automated analysis of
                      your electrical schematics, simplifying a once tedious
                      process.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="100"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 justify-end items-center">
                      <Star
                        className="text-gray-500"
                        style={{
                          width: "50px",
                          height: "50px",
                          color: "#FFF",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">PROVEN ACCURACY</h4>
                    <p className="m-0 text-sm">
                      Our system has uncovered significant errors in over 65% of
                      the schematics analysed, helping you catch issues early.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 justify-end items-center">
                      <FastForward
                        className="text-gray-500"
                        style={{
                          width: "50px",
                          height: "50px",
                          color: "#FFF",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">ACCELERATED RESULTS</h4>
                    <p className="m-0 text-sm">
                      Experience fewer re-spins, faster time to market, and
                      enhanced product quality, reliability, and safety.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="300"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 justify-end items-center">
                      <Handshake
                        className="text-gray-500"
                        style={{
                          width: "50px",
                          height: "50px",
                          color: "#FFF",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">SECURE & USER-FRIENDLY</h4>
                    <p className="m-0 text-sm">
                      Enjoy a user-friendly interface with robust data
                      security—your Netlist and BOM files are never stored.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16 justify-end items-center">
                      <Workflow
                        className="text-gray-500"
                        style={{
                          width: "50px",
                          height: "50px",
                          color: "#FFF",
                          padding: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">OPTIMISE WORKFLOW</h4>
                    <p className="m-0 text-sm">
                      Enhance your design process, reduce costs, and speed up
                      time to market, all while ensuring your end product meets
                      the highest standards of quality and reliability.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="500"
              >
                {/*  <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/feature-tile-icon-06.svg')}
                        alt="Features tile icon 06"
                        width={64}
                        height={64} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Robust Workflow
                    </h4>
                    <p className="m-0 text-sm">
                      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
