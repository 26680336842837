import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import ContactForm from "../layout/partials/contactForm";
import SectionHeader from "./partials/SectionHeader";
const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Cta extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "cta section center-content-mobile",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      //hasBgColor && "has-bg-color",
      //invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      //"cta-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "cta-split"
    );


    return (
      <section id="requestDemo" {...props} className={outerClasses}
      style={{
        backgroundImage: `url(formbg.png)`,
        backgroundSize: "cover",
        backgroundColor: "white",
      }}>
        <div>
        <div style={{marginTop:'10px'}}>
          <div
            style={{ maxWidth: "1300px", alignItems: "center", margin: "auto" }}
          >

            <div style={{ textAlign: "center", alignItems: "center", color:'black'}}>
              <h2>Request for demo</h2>
            </div>
            <div
              className={innerClasses}
              
            >
              <div className="cta-slogan" > 
                <h3  style={{color:"white"}}>Experience CircuitSight in Action</h3>
                <p style={{ color: "white" }}>
                  Unlock the power of advanced AI-driven circuit analysis.
                </p>
                <p style={{ color: "white", marginTop: "-23px" }}>
                  Request a personalized demo to see how CircuitSight
                </p>
                <p style={{ color: "white", marginTop: "-23px" }}>
                  can optimize your designs and accelerate your projects.
                </p>
              </div>
              <div className="cta-action">
                {/*  <Input id="newsletter" type="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your best email">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
                </svg>
              </Input> */}
                <ContactForm />
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
