import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Tabs, { TabList, Tab, TabPanel } from "./../elements/Tabs";
import Image from "../elements/Image";
import { Check } from "lucide-react";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class FeaturesTabs extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tabs section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tabs-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Features",
      paragraph:
        "Empowering your design process with automation, Real-Time Insight, and seamless integration.",
    };

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    return (
      <section {...props} className={outerClasses}
       id="features"
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Tabs active="tab-a">
              <TabList>
                <Tab tabId="tab-a">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("./../../assets/images/guidelines.png")}
                      alt="Tab icon 01"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Advanced Voltage Analysis
                  </div>
                </Tab>
                <Tab tabId="tab-b">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("./../../assets/images/guidelines.png")}
                      alt="Tab icon 02"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Communications Protocol Validation
                  </div>
                </Tab>
                <Tab tabId="tab-c">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("./../../assets/images/guidelines.png")}
                      alt="Tab icon 03"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Connection Verification
                  </div>
                </Tab>
                <Tab tabId="tab-d">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("./../../assets/images/guidelines.png")}
                      alt="Tab icon 04"
                      width={30}
                      height={30}
                    />
                  </div>
                  <div className="text-color-high fw-600 text-sm">
                    Practice Guidelines
                  </div>
                </Tab>
              
              </TabList>
              <TabPanel id="tab-a">
                <div
                  // className="card-content-image-container"
                  style={{
                    display: "flex", 
                     flexDirection: isMobile ? "column" : "row", 
                    padding: isMobile ? "10px" : "20px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    maxWidth: "90%",
                    margin: "auto",
                    backgroundColor: "#eff0ff",
                    height: isMobile? "auto":"auto",
                  }}
                >
                  <div
                    style={{
                      //  boxShadow: "0 0 10px 0px rgba(0, 0, 0, 0.1)",
                      textAlign: "left",
                      padding: "25px 30px",
                    }}
                  >
                    <h5 style={{ marginTop: "-15px" }}>
                      Advanced Voltage Analysis
                    </h5>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <p>
                        <Check
                          style={{
                            color: " #f45513",
                            fontWeight: "bold",
                          }}
                          size={isMobile ? 30 : 22}
                        />
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light ">
                        Uncover Activity Level Inconsistencies.
                      </p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center",gap: "10px", }}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",
                            fontWeight: "bold", }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light ">
                        Spot Supply Voltage Violations
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'center',gap:'10px'}}>
                      <p>
                        {" "}
                        <Check   size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold', }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Resolve Voltage Calculation Errors
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'start',gap:'10px'}}>
                      <p>
                        {" "}
                        <Check  size={isMobile ? 30 : 22} style={{ color: " #f45513" ,fontWeight:'bold',}} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">{""}
                        Detect unexpected voltage drops or surges.
                      </p>
                    </div>
                    <div style={{ display: "flex",flexDirection:'row',alignItems:'start',gap:'10px' }}>
                      <p>
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold', }} />{""}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Ensure proper power distribution across the circuit.
                      </p>
                    </div>
                  </div>
                  {/* Image Section */}
                  <div
                   // className="features-tabs-tab-image"
                    style={{ textAlign: "center" }}
                  >
                    <Image
                     // className="has-shadow"
                      src={require("./../../assets/images/voltage.png")}
                      alt="Features tabs image"
                      width={500}
                      height={504}
                      style={{
                        borderRadius: "8px",
                        marginTop:'30px'
                       // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel id="tab-b">
                {/*  <Image
                  className="has-shadow"
                  src={require("./../../assets/images/features-tabs-image.png")}
                  alt="Features tabs image 02"
                  width={896}
                  height={504}
                  
                /> */}
                <div
                  className="card-content-image-container"
                  style={{
                    display: "flex", 
                     flexDirection: isMobile ? "column" : "row", 
                    padding: isMobile ? "10px" : "20px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    maxWidth: "90%",
                    margin: "auto",
                    backgroundColor: "#eff0ff",
                    height: isMobile? "auto":"auto",
                  }}
                >
                  <div
                    style={{
                      //  boxShadow: "0 0 10px 0px rgba(0, 0, 0, 0.1)",
                      textAlign: "left",
                      padding: "25px 30px",
                    }}
                  >
                    <h5 style={{ marginTop: "-15px" }}>
                      Communication Protocol Validation
                    </h5>
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold' }} />{" "}
                      </p>

                      <p className="text-[#3f444b] leading-normal font-light">
                        Avoid Misconfigurations in I2C, SPI, UART, RS-485/232,
                        and USB Protocols
                      </p>
                    </div>
                    <div style={{ display: "flex",alignItems:'start',gap:"10px" }}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold' }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light ">
                        Ensure Proper Application of Connectors
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check  size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold'}} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Guarantee RF Connection Continuity
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold' }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Verify signal integrity across communication channels.
                      </p>
                    </div>
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513" ,fontWeight:'bold'}} />
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Detects and addresses timing mismatches in protocol
                        communication.
                      </p>
                    </div>
                  </div>
                  {/* Image Section */}
                  <div
                   // className="features-tabs-tab-image"
                    style={{ textAlign: "center" }}
                  >
                    <Image
                      //className="has-shadow"
                      src={require("./../../assets/images/protocol.png")}
                      alt="Features tabs image"
                      width={550}
                      height={504}
                      style={{
                        borderRadius: "8px",
                        marginTop:'60px'
                       // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel id="tab-c">
                <div
                  // className="card-content-image-container"
                  style={{
                    display: "flex", 
                     flexDirection: isMobile ? "column" : "row", 
                    padding: isMobile ? "10px" : "20px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    maxWidth: "90%",
                    margin: "auto",
                    backgroundColor: "#eff0ff",
                    height: isMobile? "auto":"auto",
                  }}
                >
                  <div
                    style={{
                      //  boxShadow: "0 0 10px 0px rgba(0, 0, 0, 0.1)",
                      textAlign: "left",
                      padding: "25px 30px",
                    }}
                  >
                    <h5 style={{ marginTop: "-15px" }}>
                      Connection Verification
                    </h5>
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold' }} />{" "}
                      </p>

                      <p className="text-[#3f444b] leading-normal font-light ">
                        Identify and Correct Datasheet Instruction Violations
                      </p>
                    </div>
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check  size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold' }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light ">
                        Eliminate Floating Logic Pins
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513" ,fontWeight:'bold'}} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Ensure Proper Use of Passive Components
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513" ,fontWeight:'bold'}} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Verify correct polarity and orientation of components.
                      </p>
                    </div>
                    <div style={{ display: "flex",alignItems:'start',gap:"10px" }}>
                      <p>
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513" ,fontWeight:'bold'}} />
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Detects and resolves incomplete or missing connections.
                      </p>
                    </div>
                  </div>
                  {/* Image Section */}
                  <div
                    //className="features-tabs-tab-image"
                    style={{ textAlign: "center" }}
                  >
                    <Image
                      //className="has-shadow"
                      src={require("./../../assets/images/verification.png")}
                      alt="Features tabs image"
                      width={500}
                      height={504}
                      style={{
                        borderRadius: "8px",
                        marginTop:'30px'
                       // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel id="tab-d">
                <div
                  // className="card-content-image-container"
                  style={{
                    display: "flex", 
                     flexDirection: isMobile ? "column" : "row", 
                    padding: isMobile ? "10px" : "20px",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    maxWidth: "90%",
                    margin: "auto",
                    backgroundColor: "#eff0ff",
                    height: isMobile? "auto":"auto",
                  }}
                >
                  <div
                    style={{
                      //  boxShadow: "0 0 10px 0px rgba(0, 0, 0, 0.1)",
                      textAlign: "left",
                      padding: "25px 30px",
                    }}
                  >
                    <h5 style={{ marginTop: "-15px" }}>Practice Guidelines</h5>
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513" ,fontWeight:'bold'}} />{" "}
                      </p>

                      <p className="text-[#3f444b] leading-normal font-light ">
                        Maintain Clear Net Naming Conventions
                      </p>
                    </div>
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold' }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light ">
                        Perform Comprehensive Testability Evaluations
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513" }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Eliminate Unnecessary Pull Resistors
                      </p>
                    </div>{" "}
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        {" "}
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513",fontWeight:'bold' }} />{" "}
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Optimise trace routing for signal integrity.
                      </p>
                    </div>
                    <div style={{ display: "flex" ,alignItems:'start',gap:"10px"}}>
                      <p>
                        <Check size={isMobile ? 30 : 22} style={{ color: " #f45513" ,fontWeight:'bold'}} />
                      </p>
                      <p className="text-[#3f444b] leading-normal font-light">
                        Standardise component placement for improved design
                        efficiency.
                      </p>
                    </div>
                  </div>
                  {/* Image Section */}
                  <div
                    //className="features-tabs-tab-image"
                    style={{ textAlign: "center" }}
                  >
                    <Image
                      //className="has-shadow"
                      src={require("./../../assets/images/practice.png")}
                      alt="Features tabs image"
                      width={550}
                      height={504}
                      style={{
                        borderRadius: "8px",
                        marginTop:'40px'
                        //boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
            
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTabs.propTypes = propTypes;
FeaturesTabs.defaultProps = defaultProps;

export default FeaturesTabs;
