import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./partials/Logo";

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  active: false,
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

class Header extends React.Component {
  state = {
    isActive: false,
    activeSection: "home",
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener("keydown", this.keyPress);
    document.addEventListener("click", this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
    document.addEventListener("click", this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + "px";
    this.setState({ isActive: true });
  };

  closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  };

  keyPress = (e) => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  };

  clickOutside = (e) => {
    if (!this.nav.current) return;
    if (
      !this.state.isActive ||
      this.nav.current.contains(e.target) ||
      e.target === this.hamburger.current
    )
      return;
    this.closeMenu();
  };

  onMenuClick = (input) => {
    this.closeMenu();
    this.setState({ activeSection: input });
  };

  render() {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      ...props
    } = this.props;

    const classes = classNames(
      "site-header",
      bottomOuterDivider && "has-bottom-divider",
      className
    );

    return (
      <header {...props} className={classes}>
        <div style={{maxWidth:'1300px',margin :'auto'}}>
          <div
            className={classNames(
              "site-header-inner",
              bottomDivider && "has-bottom-divider"
            )}
          >
            <Logo />
            {!hideNav && (
              <React.Fragment>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={this.nav}
                  className={classNames(
                    "header-nav",
                    this.state.isActive && "is-active"
                  )}
                >
                  <div className="header-nav-inner">
                    <ul
                      className={classNames(
                        "list-reset text-xs",
                        navPosition && `header-nav-${navPosition}`
                      )}
                    >
                      {/* #005776 blue
     orange
 */}{" "}
                      <li>
                        <a
                          href="#home"
                          onClick={() => this.onMenuClick("home")}
                          style={{
                            color:
                              this.state.activeSection == "home"
                                ? "#f74602"
                                : "#005776",
                          }}
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a
                          href="#whyChoose"
                          onClick={() => this.onMenuClick("whyChoose")}
                          style={{
                            color:
                              this.state.activeSection == "whyChoose"
                                ? "#f74602"
                                : "#005776",
                          }}
                        >
                          Why CircuitSight.ai?
                        </a>
                      </li>
                      <li>
                        <a
                          href="#features"
                          onClick={() => this.onMenuClick("features")}
                          style={{
                            color:
                              this.state.activeSection == "features"
                                ? "#f74602"
                                : "#005776",
                          }}
                        >
                          Features
                        </a>
                      </li>
                      <li>
                        <a
                          href="#demo"
                          onClick={() => this.onMenuClick("demo")}
                          style={{
                            color:
                              this.state.activeSection == "demo"
                                ? "#f74602"
                                : "#005776",
                          }}
                        >
                          How to use
                        </a>
                      </li>
                      <li>
                        <a
                          href="#requestDemo"
                          onClick={() => this.onMenuClick("requestDemo")}
                          style={{
                            color:
                              this.state.activeSection == "requestDemo"
                                ? "#f74602"
                                : "#005776",
                          }}
                        >
                          Request Demo
                        </a>
                      </li>
                      <li>
                        {/*                         <a href="https://dev-app.circuitsight.ai/signup" onClick={()=>this.onMenuClick('signup')}  style={{color:(this.state.activeSection=='signup')? "#f74602":'#005776'}}>
                          Sign up
                        </a> */}
                        <button
                          onClick={() => this.onMenuClick("signup")}
                          style={{
                            backgroundColor: "#f74602",
                            height: "auto",
                            padding: "0.5rem 1.5rem",
                            lineHight: "1.5",
                            textAlign: "center",
                            borderRadius: "20px",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "white";
                            e.currentTarget.querySelector("a").style.color =
                              "#f74602";
                            e.currentTarget.style.border = "2px solid #f74602";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = "#f74602";
                            e.currentTarget.querySelector("a").style.color =
                              "white";
                          }}
                        >
                          {" "}
                          <a
                            href="https://dev-app.circuitsight.ai/signup"
                            style={{
                              color:
                                this.state.activeSection == "signup"
                                  ? "#f74602"
                                  : "white",
                            }}
                          >
                            Sign Up
                          </a>
                        </button>
                      </li>
                    </ul>
                  
                  </div>
                </nav>
              </React.Fragment>
            )}
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
