import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Timeline from "../elements/Timeline";
import TimelineItem from "../elements/TimelineItem";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class Roadmap extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "roadmap section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "roadmap-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "How To Use Circuitsight.ai",
      paragraph:
        "Learn how to effortlessly optimize your electrical schematic design using Circuitsight.ai's intuitive features and automated workflow.",
    };

    return (
      <section {...props} className={outerClasses} id="demo">
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Create Baord">
                Go to 'Boards' and create a new board for analysis. Add a board
                name, select a category, then select or add a new configuration
                for your board. Write a board description.{" "}
              </TimelineItem>
              <TimelineItem title="Upload Files">
                Upload your BOM (Bill of Materials) and Netlist files.
                Circuitsight.ai accepts various formats like .csv, .xlsx, and
                .xls for BOMs and supports Netlist formats from major ECAD
                vendors.
              </TimelineItem>
              <TimelineItem title="Click Analyze">
                Click the "Analyze" button to start processing your uploaded
                files.
              </TimelineItem>
              <TimelineItem title="Get Reports">
                After the analysis, reports are automatically generated. You can
                find them under the "My Reports" section on the platform.
              </TimelineItem>
              <TimelineItem title="Email Notification">
                You’ll receive an email when your analysis is complete, so
                you’ll know when to check your results.
              </TimelineItem>
              <TimelineItem title="View Report">
                Open and review the detailed inspection report to see the
                insights and findings from your BOM and Netlist files.
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Roadmap.propTypes = propTypes;
Roadmap.defaultProps = defaultProps;

export default Roadmap;
